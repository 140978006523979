import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import "./assets/style/style.css";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
	<React.StrictMode>
    	<MsalProvider instance={msalInstance}>
      		<App />
    	</MsalProvider>
  	</React.StrictMode>
);
