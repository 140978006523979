import { useMsal } from '@azure/msal-react';
import React from 'react';

const Schrottwichteln: React.FC = () => {

    const [isClicked, setIsClicked] = React.useState(false);
    const [wichtel, setWichtel] = React.useState("");

    // get logged in accounts fro msal provider
    const { accounts } = useMsal();

    const getClickedClass = () => {
        return ( isClicked ) ? ' clicked' : '';
    };

    React.useEffect(() => {
        const getWichtel = async () => {
            const apiResponse = await fetch("/api.php", {
                method: "POST",
                headers: { "Content-Type": "application/json", },
                mode: "no-cors",
                body: JSON.stringify({ email: accounts[0]?.username })
            });

            const responseJson = await apiResponse.json();

            setWichtel(await responseJson.name);
        }


        getWichtel();
    // eslint-disable-next-line
    }, []);


    return (
        <div className='Schrottwichteln'>
            <h1>Willkommen zum Schrottwichteln <span className='name'>{ accounts[0]?.name }</span></h1>
            <span className='beschreibung'>Nutze das Schrottwichteln, um Zuhause wieder etwas Platz zu schaffen! Behalte den Namen deines Wichtel-Partners geheim, tauschen ist nicht gestattet, und beschenke ihn oder sie mit einer nutzlosen oder skurrilen Kleinigkeit (Warenwert nicht höher als 5 €) die deinen Stauraum strapaziert oder schon seit Jahren nur als Staubfänger dient. Eventuell kannst Du einer anderen Person damit noch einen großen Gefallen tun 😉 Packe es geschmackvoll ein und versehe es mit dem Namen deines Wichtel-Partners. Gebe es bis zum 13.12. am Empfang ab.</span>
            <div className={'zettelHolder' + getClickedClass()} onClick={() => setIsClicked(true)}>
                <span className='zettelTop'>Ziehe deinen Partner</span>
                <span className='zettelBottom'>{wichtel}</span>
            </div>
        </div>
    );
};

export default Schrottwichteln;