import React, { useEffect } from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from './authConfig';
import Schrottwichteln from './components/Schrottwichteln';

const App: React.FC =() => {
	// Login User when opening app, trying sillentsso so user doesn`t notice login each time
    const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

	// if silentsso wasn't sucessfull and the login need interaction from user, redirect to login page
    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, loginRequest);
        }
    }, [login, error]);

    return (
        <div className='App'>
            <AuthenticatedTemplate>
                <Schrottwichteln />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
				<h1>Willkommen zum Schrottwichteln</h1>
            </UnauthenticatedTemplate>
        </div>
    );
}

export default App;